import {
  GET_SALES_DATA_BY_DATE,
  GET_SALES_DATA_BY_DATE_RANGE,
  GET_PAYMENT_METHOD_WISE_BILL_COUNT,
  GET_SALES_DATA_AGAINST_DATE,
  GET_PAYMENT_METHOD_WISE_BILL_COUNT_RANGE,
  SET_RANGE_STATE_CHANGE_LOADING,
  SET_TODAY_STATE_CHANGE_LOADING,
} from "./types";
import moment from "moment/moment";
import store from "../../../store";
import Axios from "../../../common/util/Axios";

// Submit a form request
export const getSalesDataByDate = date => async dispatch => {
  try {
    dispatch({ type: SET_TODAY_STATE_CHANGE_LOADING });
    const branchId = store.getState().authState.branchId;
    const res = await Axios.get(`/api/dashboard/purchase/find-sales-by-date`, {
      params: { date: moment(date).format("YYYY-MM-DD"), branchId: branchId },
    });

    dispatch({
      type: GET_SALES_DATA_BY_DATE,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getSalesDataByDateRange = dateRange => async dispatch => {
  try {
    dispatch({ type: SET_RANGE_STATE_CHANGE_LOADING });
    const branchId = store.getState().authState.branchId;
    const res = await Axios.get(
      `/api/dashboard/purchase/find-sales-by-date-range`,
      {
        params: {
          fromDate: moment(dateRange[0]).format("YYYY-MM-DD"),
          toDate: moment(dateRange[1]).format("YYYY-MM-DD"),
          branchId: branchId,
        },
      }
    );

    dispatch({
      type: GET_SALES_DATA_BY_DATE_RANGE,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getPaymentMethodUsedForToday = date => async dispatch => {
  try {
    dispatch({ type: SET_TODAY_STATE_CHANGE_LOADING });
    const branchId = store.getState().authState.branchId;
    const res = await Axios.get(
      `/api/dashboard/purchase/find-payment-method-wise-bill-count`,
      {
        params: {
          date: moment(date).format("YYYY-MM-DD"),
          branchId: branchId,
        },
      }
    );

    dispatch({
      type: GET_PAYMENT_METHOD_WISE_BILL_COUNT,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getSalesDataAgainstDate = dateRange => async dispatch => {
  try {
    dispatch({ type: SET_RANGE_STATE_CHANGE_LOADING });
    const branchId = store.getState().authState.branchId;
    const res = await Axios.get(
      `/api/dashboard/purchase/find-sales-against-date`,
      {
        params: {
          fromDate: moment(dateRange[0]).format("YYYY-MM-DD"),
          toDate: moment(dateRange[1]).format("YYYY-MM-DD"),
          branchId: branchId,
        },
      }
    );

    dispatch({
      type: GET_SALES_DATA_AGAINST_DATE,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getPaymentMethodUsedForRange = dateRange => async dispatch => {
  try {
    dispatch({ type: SET_RANGE_STATE_CHANGE_LOADING });
    const branchId = store.getState().authState.branchId;
    const res = await Axios.get(
      `/api/dashboard/purchase/find-payment-method-wise-bill-count`,
      {
        params: {
          fromDate: moment(dateRange[0]).format("YYYY-MM-DD"),
          toDate: moment(dateRange[1]).format("YYYY-MM-DD"),
          branchId: branchId,
        },
      }
    );

    dispatch({
      type: GET_PAYMENT_METHOD_WISE_BILL_COUNT_RANGE,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err);
  }
};
