import "./index.css";
import { Outlet, Navigate } from "react-router-dom";
import Logo from "./logo";
import SideNavBar from "./sideNavBar";
import TopNavBar from "./topNavBar";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../auth/actions";
import Footer from "./footer";
import React from "react";

const Layout = ({ auth: { isAuthenticated }, logout }) => {
  return true ? (
    <div className="flex-container">
      <div className="left-container">
        <div className="logo-container">
          <Logo />
        </div>
        <div className="side-nav-bar-container">
          <SideNavBar />
        </div>
      </div>
      <div className="right-container">
        <div className="top-nav-bar-outer-container">
          <TopNavBar logout={logout} />
        </div>
        <div>
          <div>
            <Outlet />
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

Layout.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.authState,
});

export default connect(mapStateToProps, { logout })(Layout);
