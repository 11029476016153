import { Outlet, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect } from "react";
import setHeaders from "./modules/dashboard/utils/CommonHeaders";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { verifyToken } from "./modules/auth/actions";

const MainContainer = ({
  verifyToken,
  auth: { isAuthenticated, isFailed, token },
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const tokenLocal = Cookies.get("token_");
    if (tokenLocal) {
      verifyToken(tokenLocal);
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      setHeaders(token);
      navigate("/");
    } else if (isFailed) {
      navigate("/login");
    }
  }, [isAuthenticated, isFailed]);

  return (
    <>
      <Outlet />
    </>
  );
};

MainContainer.propTypes = {
  verifyToken: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.authState,
});

export default connect(mapStateToProps, {
  verifyToken,
})(MainContainer);
