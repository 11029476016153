import "./index.css";
import CardMedium from "./CardMedium";
import PieChartCard from "./PieChartCard";
import BasicDateRangePicker from "./BasicDateRangePicker";
import CardSmall from "./CardSmall";
import BasicLineChart from "./BasicLineChart";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { InputLabel } from "@mui/material";
import {
  getSalesDataByDate,
  getSalesDataByDateRange,
  getPaymentMethodUsedForToday,
  getSalesDataAgainstDate,
  getPaymentMethodUsedForRange,
} from "../actions/index";
import { setBranchId } from "../../auth/actions";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";

const Dashboard = ({
  getSalesDataByDate,
  getSalesDataByDateRange,
  getPaymentMethodUsedForToday,
  getSalesDataAgainstDate,
  getPaymentMethodUsedForRange,
  setBranchId,
  dashboard: {
    totalSalesToday,
    totalCostToday,
    totalProfitToday,
    numberOfBillsToday,
    loadingTodayData,
    totalSalesRange,
    totalCostRange,
    totalProfitRange,
    numberOfBillsRange,
    loadingRangeData,
    salesWiseBillCountToday,
    pieChartTodayLoading,
    salesWiseBillCountRange,
    pieChartRangeLoading,
    loadingGraphData,
  },
  auth: { branchList, branchId },
}) => {
  let weekBeforeDate = new Date();
  weekBeforeDate.setDate(weekBeforeDate.getDate() - 7);

  const [dateRange, setDateRange] = React.useState([
    weekBeforeDate,
    new Date(),
  ]);

  useEffect(() => {
    if (branchId) {
      getSalesDataByDate(new Date());
      getPaymentMethodUsedForToday(new Date());
    }
  }, [branchId]);

  useEffect(() => {
    if (branchId) {
      getSalesDataByDateRange(dateRange);
      getSalesDataAgainstDate(dateRange);
      getPaymentMethodUsedForRange(dateRange);
    }
  }, [dateRange, branchId]);

  const breakpoints = {
    margin: 0.5,
    borderRadius: "10px",
    flex: {
      xs: "100%",
      sm: "calc(50% - 50px)",
      md: "calc(33% - 50px)",
      lg: "calc(25% - 50px)",
    },
  };

  const handleChangeBranch = event => {
    setBranchId(event.target.value);
  };

  const handleChange = () => {};

  return (
    <div className="container">
      <div className="header">
        <h1>Dashboard</h1>
        {branchId && (
          <FormControl className="branch-select-dropdown" size="small">
            <InputLabel id="test-select-label">Branch</InputLabel>
            <Select
              labelId="test-select-label"
              id="demo-simple-select-helper"
              value={branchId}
              label="Branch"
              onChange={handleChangeBranch}
            >
              {branchList.map(item => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      <div className="body">
        <div className="date-picker">
          <FormControl sx={{ minWidth: 120 }} size="small">
            <Select
              value={"Today"}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="Today">Today</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="top-container">
          <div className="top-container-left">
            {loadingTodayData ? (
              <>
                <Skeleton
                  height={"120px"}
                  variant="rectangular"
                  sx={breakpoints}
                />
                <Skeleton
                  height={"120px"}
                  variant="rectangular"
                  sx={breakpoints}
                />
                <Skeleton
                  height={"120px"}
                  variant="rectangular"
                  sx={breakpoints}
                />
                <Skeleton
                  height={"120px"}
                  variant="rectangular"
                  sx={breakpoints}
                />
              </>
            ) : (
              <>
                <CardMedium
                  amount={totalSalesToday}
                  color="green"
                  title="Total Sales"
                  value="0%"
                />
                <CardMedium
                  amount={totalCostToday}
                  color="green"
                  title="Total Cost"
                  value="0%"
                />
                <CardMedium
                  amount={totalProfitToday}
                  color="green"
                  title="Total Profit"
                  value="0%"
                />
                <CardMedium
                  amount={numberOfBillsToday}
                  color="green"
                  title="Number of Bills"
                  value="0%"
                />
              </>
            )}
          </div>
          <div className="top-container-right">
            {pieChartTodayLoading ? (
              <Skeleton
                variant="rectangular"
                sx={{
                  margin: 0.5,
                  borderRadius: "10px",
                  height: "300px",
                }}
              />
            ) : (
              <PieChartCard
                title="Payment Methods Usage"
                data={salesWiseBillCountToday}
              />
            )}
          </div>
        </div>
        <div className="bottom-container">
          <BasicDateRangePicker
            setDateRange={setDateRange}
            dateRange={dateRange}
          />
          <div className="card-container">
            {loadingRangeData ? (
              <>
                <Skeleton
                  height={"70px"}
                  variant="rectangular"
                  sx={breakpoints}
                />
                <Skeleton
                  height={"70px"}
                  variant="rectangular"
                  sx={breakpoints}
                />
                <Skeleton
                  height={"70px"}
                  variant="rectangular"
                  sx={breakpoints}
                />
                <Skeleton
                  height={"70px"}
                  variant="rectangular"
                  sx={breakpoints}
                />
              </>
            ) : (
              <>
                <CardSmall
                  amount={totalSalesRange}
                  color="green"
                  title="Total Sales"
                  value="0%"
                />
                <CardSmall
                  amount={totalCostRange}
                  color="green"
                  title="Total Cost"
                  value="0%"
                />
                <CardSmall
                  amount={totalProfitRange}
                  color="green"
                  title="Total Profit"
                  value="0%"
                />
                <CardSmall
                  amount={numberOfBillsRange}
                  color="green"
                  title="Number of Bills"
                  value="0%"
                />
              </>
            )}
          </div>
          <div className="bottom-chart-container">
            <div className="chart-container-left">
              {pieChartRangeLoading ? (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    margin: 0.5,
                    borderRadius: "10px",
                    height: "300px",
                  }}
                />
              ) : (
                <PieChartCard
                  title="Payment Methods Usage"
                  data={salesWiseBillCountRange}
                />
              )}
            </div>
            <div className="chart-container-right">
              {loadingGraphData ? (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    margin: 0.5,
                    borderRadius: "10px",
                    height: "350px",
                  }}
                />
              ) : (
                <BasicLineChart />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  getSalesDataByDate: PropTypes.func.isRequired,
  getSalesDataByDateRange: PropTypes.func.isRequired,
  getPaymentMethodUsedForToday: PropTypes.func.isRequired,
  getSalesDataAgainstDate: PropTypes.func.isRequired,
  getPaymentMethodUsedForRange: PropTypes.func.isRequired,
  setBranchId: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  dashboard: state.dashboard,
  auth: state.authState,
});

export default connect(mapStateToProps, {
  getSalesDataByDate,
  getSalesDataByDateRange,
  getPaymentMethodUsedForToday,
  getSalesDataAgainstDate,
  getPaymentMethodUsedForRange,
  setBranchId,
})(Dashboard);
