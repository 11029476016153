import Snackbar from '@mui/joy/Snackbar';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { showAlert } from '../../actions';

const Alert = ({ common: { isAlertOpen, alertType, alertMessage }, variant,  message, showAlert }) => {
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={2000}
            open={isAlertOpen}
            variant="solid"
            color={alertType}
            onClose={(event, reason) => {
                if (reason === 'clickaway') {
                    return;
                }
                showAlert({isAlertOpen: false});
            }}
        >
            {alertMessage}
        </Snackbar>
    );
}

Alert.propTypes = {
    login: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    common: state.commonState,
});

export default connect(mapStateToProps, {
    showAlert
})(Alert);