import React from "react";
import styles from "./index.module.css";
import { FaSearch } from "react-icons/fa";

const SearchBar = () => {
  return (
    <div className={styles.mainDiv}>
      <div className={styles[`search-bar`]}>
        <FaSearch className={styles[`search-icon`]} />
        <input type="text" placeholder="Search..." />
      </div>
    </div>
  );
};

export default SearchBar;
