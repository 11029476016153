import React, { useState, memo, useEffect } from "react";
import styles from "./index.module.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const TableComp = memo(({
  invoiceStockData,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    {
      id: "product",
      label: "Product",
      minWidth: 60,
      format: (object) => (object.code + " " + object.name),
    },
    {
      id: "qty",
      label: "Quantity",
      minWidth: 30,
    },
    {
      id: "unitPrice",
      label: "Buying Price",
      minWidth: 30,
    },
    {
      id: "discountType",
      label: "Discount Type",
      minWidth: 30,
    },
    {
      id: "discount",
      label: "Discount",
      minWidth: 30,
      format: (object) => {
        return object.value ? object.value + " " + object.unit : "";
      },
    },
    {
      id: "totalAmount",
      label: "Amount",
      minWidth: 30,
    },
    {
      id: "sellingPrice",
      label: "Selling Price",
      minWidth: 30,
    },
    {
      id: "sellingDiscount",
      label: "Selling Discount",
      minWidth: 30,
      format: (object) => {
        return object.value ? object.value + " " + object.unit : "";
      },
    },
    {
      id: "sellingLoyaltyDiscount",
      label: "Loyalty Discount",
      minWidth: 30,
      format: (object) => {
        return object.value ? object.value + " " + object.unit : "";
      },
    },
    {
      id: "sellingWholesaleDiscount",
      label: "Wholesale Discount",
      minWidth: 30,
      format: (object) => {
        return object.value ? object.value + " " + object.unit : "";
      },
    },
  ];

  console.log("table data of child: ", invoiceStockData);

  return (
    <div className={styles.mainDiv}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer style={{ height: '50vh' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    // align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceStockData
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id}>
                            {column.format
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
});

TableComp.propTypes = {

};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, {})(TableComp);
