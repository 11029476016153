import {
    GET_SUPPLIER_INVOICE_DATA,
    SET_SUPPLIER_DATA_LOADING,
    GET_ALL_SUPPLIERS,
    GET_SEARCH_PRODUCTS,
} from "../actions/types";

const initialState = {
    isLoading: false,
    supplierInvoiceData: [],
    allSuppliers: [],
    suggestProducts: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_SUPPLIER_DATA_LOADING:
            return {
                ...state,
                isLoading: payload,
            };
        case GET_SUPPLIER_INVOICE_DATA:
            return {
                ...state,
                supplierInvoiceData: payload,
                isLoading: false,
            };
        case GET_ALL_SUPPLIERS:
            return {
                ...state,
                allSuppliers: payload,
            };
        case GET_SEARCH_PRODUCTS:
            console.log(payload)
            return {
                ...state,
                suggestProducts: payload,
            };
        default:
            return state;
    }
}
