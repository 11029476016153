import axios from "axios";

import { SET_ALERT_OPEN, SET_MOB_SIDE_NAV_BAR_VISIBILITY } from "./types";

export const showAlert = (props) => async (dispatch) => {
  dispatch({
    type: SET_ALERT_OPEN,
    payload: props,
  });
};

export const setMobSideNavBarVisibility =
  (current_visibility) => async (dispatch) => {
    dispatch({
      type: SET_MOB_SIDE_NAV_BAR_VISIBILITY,
      payload: !current_visibility,
    });
  };
