import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import { connect } from "react-redux";
import { setBranchId } from "../../auth/actions";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { InputLabel } from "@mui/material";
import PropTypes from "prop-types";
import { BiSolidLayer } from "react-icons/bi";
import FilterDropDown from "./FilterDopdown";
import SearchBar from "./SearchBar";
import TableComp from "./Table";

const Invoices = ({ setBranchId, auth: { branchList, branchId } }) => {
  const [selectedFilterItem, setSelectedFilterItem] = useState(null);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);

  const navigate = useNavigate();

  const onClickNewInvoice = () => {
    navigate("/inventory/invoices/new-invoice");
  }

  const handleChangeBranch = (event) => {
    setBranchId(event.target.value);
  };

  const filterDropdownItems = [
    { id: 1, name: "category one" },
    { id: 2, name: "category two" },
    { id: 3, name: "category three" },
    { id: 4, name: "category four" },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Invoices</h1>
        {branchId && (
          <FormControl className={"branch-select-dropdown"} size="small">
            <InputLabel id="test-select-label">Branch</InputLabel>
            <Select
              labelId="test-select-label"
              id="demo-simple-select-helper"
              value={branchId}
              label="Branch"
              onChange={handleChangeBranch}
            >
              {branchList.map((item) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      <div className={styles.body}>
        <div className={styles.chartMainDiv}>
          <div className={styles.topDiv}>
            <div className={styles.leftDiv}>
              <div className={styles.newStockButton} onClick={() => {onClickNewInvoice()}}>
                <BiSolidLayer className={styles.newStockButtonIcon} />
                New Invoice
              </div>
            </div>
            <div className={styles.rightDiv}>
              {/* <SearchBar />
              <>
                <FilterDropDown
                  selectedFilterItem={selectedFilterItem}
                  setSelectedFilterItem={setSelectedFilterItem}
                  showFilterDropdown={showFilterDropdown}
                  setShowFilterDropdown={setShowFilterDropdown}
                  filterDropdownItems={filterDropdownItems}
                />
              </> */}
            </div>
          </div>
          <div className={styles.chartDiv}>
            <TableComp />
          </div>
        </div>
      </div>
    </div>
  );
};

Invoices.propTypes = {
  setBranchId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  auth: state.authState,
});

export default connect(mapStateToProps, {
  setBranchId,
})(Invoices);
