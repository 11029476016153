import React, { useEffect, useState } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { connect } from "react-redux";
import "./index.css";

const BasicLineChart = ({dashboard: { salesDataAgainstDateForGraph, loadingGraphData }}) => {

  const [salesData, setSalesData] = useState([]);
  const [costData, setCostData] = useState([]);
  const [profitData, setProfitData] = useState([]);
  const [timeAxis, setTimeAxis] = useState([]);

  useEffect(() => {
    setTimeAxis(salesDataAgainstDateForGraph.map(item => item.date_of_month));
    setSalesData(salesDataAgainstDateForGraph.map(item => item.date_sales));
    setCostData(salesDataAgainstDateForGraph.map(item => item.date_cost));
    setProfitData(salesDataAgainstDateForGraph.map(item => item.date_profit));
  }, [salesDataAgainstDateForGraph]);
  
  return (
    <div className="line-chart-body">
      <LineChart
        series={[
          { data: salesData, label: "Sales", color: "#10B2D0" },
          { data: costData, label: "Cost", color: "#095664" },
          { data: profitData, label: "Profit", color: "#98D0DA" },
        ]}
        xAxis={[{ scaleType: "point", data: timeAxis }]}
        grid={{ vertical: false, horizontal: true }}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
  
})(BasicLineChart);
