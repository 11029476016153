import React, { useState, memo, useEffect } from "react";
import styles from "./index.module.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSupplierInvoiceData } from "../../actions";

const TableComp = memo(({ supplierInvoice: { isLoading, supplierInvoiceData }, getSupplierInvoiceData, auth: { branchId } }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  useEffect(() => {
    getSupplierInvoiceData();
  }, [branchId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    {
      id: "incrementId",
      label: "ID",
      minWidth: 15,
    },
    {
      id: "invoiceNo",
      label: "Invoice No",
      minWidth: 25,
    },
    {
      id: "supplierName",
      label: "Supplier",
      minWidth: 50,
    },
    {
      id: "totalAmount",
      label: "Total Amount",
      minWidth: 50,
      align: "right",
      format: (value) => value.toFixed(2),
    },
    {
      id: "totalDiscount",
      label: "Discount",
      minWidth: 50,
      align: "right",
      format: (value) => value.toFixed(2),
    },
    {
      id: "totalPayable",
      label: "Payable",
      minWidth: 50,
      align: "right",
      format: (value) => value.toFixed(2),
    },
    {
      id: "paidAmount",
      label: "Paid Amount",
      minWidth: 50,
      align: "right",
      format: (value) => value.toFixed(2),
    },
    {
      id: "balanceAmount",
      label: "Balance Amount",
      minWidth: 50,
      align: "right",
      format: (value) => value.toFixed(2),
    },
    {
      id: "paymentMethod",
      label: "Payment Method",
      minWidth: 50,
      align: "right",
    },
    {
      id: "createdAt",
      label: "Created At",
      minWidth: 50,
      align: "right",
    },
  ];

  return (
    <div className={styles.mainDiv}>
      {isLoading ? <Skeleton
        variant="rectangular"
        sx={{
          margin: 0.5,
          borderRadius: "10px",
          height: "600px",
        }}
      /> : <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer style={{ height: '60vh' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    // align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {supplierInvoiceData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={supplierInvoiceData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>}
    </div>
  );
});

TableComp.propTypes = {
  getSupplierInvoiceData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  supplierInvoice: state.supplierInvoiceState,
  auth: state.authState,
});

export default connect(mapStateToProps, {
  getSupplierInvoiceData,
})(TableComp);
