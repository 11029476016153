import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import logoImage from "../../assets/logo.png";
import SideNavBar from "../sideNavBar";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setMobSideNavBarVisibility } from "../../../../common/actions/index";
import OutsideClickHandler from "react-outside-click-handler";

const TopNavBar = ({
  logout,
  setMobSideNavBarVisibility,
  common: { showMobSideNavBar },
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };
  return (
    <div className="top-nav-bar-container">
      <OutsideClickHandler
        onOutsideClick={() => {
          setMobSideNavBarVisibility(true);
        }}
      >
        <div className="left-div">
          <>
            <HiOutlineMenuAlt2
              className="menu-icon"
              onClick={() => {
                setMobSideNavBarVisibility(showMobSideNavBar);
              }}
            />
            <div className="logo-div">
              <img src={logoImage} className="logo-img" alt="Logo" />
              <h2>EPINICS</h2>
            </div>
          </>

          <div
            className={`mob-side-nav-hide ${
              showMobSideNavBar && `mob-side-nav`
            }`}
          >
            <div className="mob-devider"></div>
            <SideNavBar />
          </div>
        </div>
      </OutsideClickHandler>
      <div className="right-div">
        <Tooltip title="Account settings">
          <IconButton
            sx={{ margin: "0 10px" }}
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>A</Avatar>
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClose}>
            <Avatar /> Profile
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Add another account
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
        <div className="notification-icon-container">
          <FontAwesomeIcon icon={faBell} />
        </div>
      </div>
    </div>
  );
};

TopNavBar.propTypes = { setMobSideNavBarVisibility: PropTypes.func.isRequired };

const mapStateToProps = state => ({
  common: state.commonState,
});

export default connect(mapStateToProps, { setMobSideNavBarVisibility })(
  TopNavBar
);
