import React, { useState } from "react";
import { connect } from "react-redux";
import styles from "./index.module.css";
import PopupModel from "../../../../../common/components/PopupModel";
import SearchableDropdown from "../../../../../common/components/SearchableDropdown";
import { MdOutlineInventory2, MdOutlinePointOfSale, MdOutlineSell } from "react-icons/md";
import { searchProducts } from "../../../actions";
import { showAlert } from "../../../../../common/actions";

const AddItemPopup = ({
    supplierInvoiceState: { suggestProducts },
    searchProducts,
    showAlert,
    addStockToInvoice,
    isOpenAddInvoicePopup,
    setOpenAddInvoicePopup,
}) => {

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isUnitPriceDisabled, setUnitPriceDisabled] = useState(false);
    const [isTotalAmountDisabled, setTotalAmountDisabled] = useState(false);

    const [formData, setFormData] = useState({
        productId: null,
        unit: null,
        qty: null,
        unitPrice: null,
        discountType: null,
        discount: {
            unit: null,
            value: null
        },
        totalAmount: null,
        sellingPrice: null,
        sellingDiscount: {
            unit: null,
            value: null
        },
        sellingLoyaltyDiscount: {
            unit: null,
            value: null
        },
        sellingWholesaleDiscount: {
            unit: null,
            value: null
        }
    });

    const onchangeBuyingValue = ({
        unitPrice = formData.unitPrice || 0,
        quantity = formData.qty || 0,
        discountType = formData.discountType,
        discountValue = formData.discount.value || 0,
        discountUnit = formData.discount.unit,
        totalAmount = formData.totalAmount || 0,
        isTotalAmountEntered = isUnitPriceDisabled,
        isUnitPriceEntered = isTotalAmountDisabled,
    }) => {

        //Calculating Discount
        let totalDiscountAmount = 0;
        if (discountType === "TOTAL_AMOUNT" && discountUnit === "Rs") {
            totalDiscountAmount = discountValue;
        } else if (discountType === "PER_UNIT" && discountUnit === "Rs") {
            totalDiscountAmount = discountValue * quantity;
        } else if (discountUnit === "%") {
            if (isTotalAmountEntered) { // user input total amount
                totalDiscountAmount = (totalAmount / (100 - discountValue)) * discountValue;
            } else if (isUnitPriceEntered) { // user input unit price
                totalDiscountAmount = (unitPrice * quantity * discountValue) / 100.0;
            }
        }

        let calculatedUnitPrice;
        let calculatedTotalCost;

        if (isTotalAmountEntered) { // user input total amount
            if (quantity > 0) {
                calculatedUnitPrice = ((totalAmount + totalDiscountAmount) / quantity).toFixed(2);
            }
        } else if (isUnitPriceEntered) { // user input unit price
            calculatedTotalCost = (unitPrice * quantity) - totalDiscountAmount;
        }

        return {
            calculatedUnitPrice: calculatedUnitPrice ? calculatedUnitPrice : formData.unitPrice,
            calculatedTotalCost: calculatedTotalCost ? calculatedTotalCost : formData.totalAmount,
        }
    }

    const onChangeUnitPrice = (event) => {
        const convertedUnitPrice = parseFloat(event.target.value);
        setTotalAmountDisabled(true);
        const { calculatedTotalCost } = onchangeBuyingValue({
            unitPrice: convertedUnitPrice,
            isTotalAmountEntered: false,
            isUnitPriceEntered: true,
        });

        setFormData({
            ...formData,
            unitPrice: convertedUnitPrice,
            totalAmount: calculatedTotalCost,
        });

    }

    const onChangeQuantity = (event) => {
        const convertedQty = parseFloat(event.target.value);
        const { calculatedUnitPrice, calculatedTotalCost } = onchangeBuyingValue({ quantity: convertedQty });
        setFormData({
            ...formData,
            qty: convertedQty,
            unitPrice: calculatedUnitPrice,
            totalAmount: calculatedTotalCost,
        });

    }

    const onSelectQtyUnit = (selectedItem) => {
        setFormData({
            ...formData,
            unit: selectedItem.name,
        });
    }

    const onSelectDiscountType = (selectedItem) => {
        const { calculatedUnitPrice, calculatedTotalCost } = onchangeBuyingValue({ discountType: selectedItem.value });
        setFormData({
            ...formData,
            discountType: selectedItem.value,
            unitPrice: calculatedUnitPrice,
            totalAmount: calculatedTotalCost,
        });
    }

    const onChangeDiscountValue = (event) => {
        const convertedDiscountValue = parseFloat(event.target.value);
        const { calculatedUnitPrice, calculatedTotalCost } = onchangeBuyingValue({ discountValue: convertedDiscountValue });
        setFormData({
            ...formData,
            discount: {
                ...formData.discount,
                value: convertedDiscountValue,
            },
            unitPrice: calculatedUnitPrice,
            totalAmount: calculatedTotalCost,
        });
    }

    const onSelectDiscountUnit = (selectedItem) => {
        const { calculatedUnitPrice, calculatedTotalCost } = onchangeBuyingValue({ discountUnit: selectedItem.name });
        setFormData({
            ...formData,
            discount: {
                ...formData.discount,
                unit: selectedItem.name,
            },
            unitPrice: calculatedUnitPrice,
            totalAmount: calculatedTotalCost,
        });
    }

    const onChangeTotalAmount = (event) => {
        const convertedTotalAmount = parseFloat(event.target.value);
        setUnitPriceDisabled(true);
        const { calculatedUnitPrice } = onchangeBuyingValue({
            totalAmount: convertedTotalAmount,
            isTotalAmountEntered: true,
            isUnitPriceEntered: false,
        });

        setFormData({
            ...formData,
            totalAmount: convertedTotalAmount,
            unitPrice: calculatedUnitPrice,
        });
    }

    const onChangeSellingPrice = (event) => {
        setFormData({
            ...formData,
            sellingPrice: parseFloat(event.target.value),
        });
    }

    const onChangeSellingDiscountValue = (event) => {
        setFormData({
            ...formData,
            sellingDiscount: {
                ...formData.sellingDiscount,
                value: parseFloat(event.target.value),
            },
        });
    }

    const onChangeSellingDiscountUnit = (selectedItem) => {
        setFormData({
            ...formData,
            sellingDiscount: {
                ...formData.sellingDiscount,
                unit: selectedItem.name,
            },
        });
    }

    const onChangeLoyaltyDiscountValue = (event) => {
        setFormData({
            ...formData,
            sellingLoyaltyDiscount: {
                ...formData.sellingLoyaltyDiscount,
                value: parseFloat(event.target.value),
            },
        });
    }

    const onChangeLoyaltyDiscountUnit = (selectedItem) => {
        setFormData({
            ...formData,
            sellingLoyaltyDiscount: {
                ...formData.sellingLoyaltyDiscount,
                unit: selectedItem.name,
            },
        });
    }

    const onChangeWholesaleDiscountValue = (event) => {
        setFormData({
            ...formData,
            sellingWholesaleDiscount: {
                ...formData.sellingWholesaleDiscount,
                value: parseFloat(event.target.value),
            },
        });
    }

    const onChangeWholesaleDiscountUnit = (selectedItem) => {
        setFormData({
            ...formData,
            sellingWholesaleDiscount: {
                ...formData.sellingWholesaleDiscount,
                unit: selectedItem.name,
            },
        });
    }

    //Submit
    const onSubmit = () => {
        if (!formData.productId) {
            showAlert({
                isAlertOpen: true,
                alertType: "danger",
                alertMessage: "Please Select a Product!",
            });
        } else if (!formData.unitPrice) {
            showAlert({
                isAlertOpen: true,
                alertType: "danger",
                alertMessage: "Please Enter an Unit Price!",
            });
        } else if (!formData.qty) {
            showAlert({
                isAlertOpen: true,
                alertType: "danger",
                alertMessage: "Please Enter a Quantity!",
            });
        } else if (!formData.unit) {
            showAlert({
                isAlertOpen: true,
                alertType: "danger",
                alertMessage: "Please Select an Unit!",
            });
        } else if (!formData.totalAmount) {
            showAlert({
                isAlertOpen: true,
                alertType: "danger",
                alertMessage: "Please Enter a Total Amount!",
            });
        } else if (!formData.sellingPrice) {
            showAlert({
                isAlertOpen: true,
                alertType: "danger",
                alertMessage: "Please Enter a Selling Price!",
            });
        } else if (formData.discount.value && !formData.discountType) {
            showAlert({
                isAlertOpen: true,
                alertType: "danger",
                alertMessage: "You Have Entered a Discount Amount. Please select the Discount Type to Continue!",
            });
        } else {
            addStockToInvoice({
                ...formData,
                product: selectedProduct,
            });
            setOpenAddInvoicePopup(false);
        }
    }

    const Title = () => {

        const onType = (keyword) => {
            searchProducts(keyword);
        }

        const onSelectProduct = (product) => {
            setSelectedProduct(product);
            setFormData({
                ...formData,
                productId: product.id,
            });
        }

        return (
            <div className={styles.headerContainer}>
                <h3>New Stock</h3>
                <div className={styles.productSelectContainer}>
                    <div className={styles.textInputContainer}>
                        <h4 className={styles.textInputLabel}>Select Product by Name, Code, or Barcode</h4>
                        <div className={styles.dropdownContainer}>
                            <SearchableDropdown
                                options={suggestProducts}
                                showKeys={["code", "name"]}
                                placeholder="Not Selected"
                                onSelect={onSelectProduct}
                                onType={onType}
                            />
                        </div>
                    </div>
                    <div className={styles.selectedProductContainer}>
                        <div className={styles.selectedProductNameContainer}>
                            <h5 className={styles.textInputLabel} >
                                Selected Product
                            </h5>
                            <h4 className={styles.productName}>
                                {selectedProduct && selectedProduct.name}
                            </h4>
                        </div>
                        <div className={styles.selectedProductIconContainer}>
                            <MdOutlineInventory2 />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const Content = () => {

        const qtyUnits = [
            {
                id: 1,
                name: "pcs",
            },
            {
                id: 2,
                name: "kg",
            },
            {
                id: 3,
                name: "g",
            },
            {
                id: 4,
                name: "l",
            },
            {
                id: 5,
                name: "ml",
            },
            {
                id: 6,
                name: "m",
            },
            {
                id: 7,
                name: "cm",
            },
        ];

        const discountTypes = [
            {
                id: 1,
                value: "TOTAL_AMOUNT",
                name: "Total Amount",
            },
            {
                id: 2,
                value: "PER_UNIT",
                name: "Per Unit",
            },
        ];

        const discountUnits = [
            {
                id: 1,
                name: "Rs",
            },
            {
                id: 2,
                name: "%",
            },
        ];

        return (
            <div>
                <section>
                    <div className={styles.sectionTitleContainer}>
                        <MdOutlinePointOfSale />
                        <h4 className={styles.sectionTitle}>BUYING SECTION</h4>
                    </div>
                    <div className={styles.topDiv}>
                        <div className={styles.textInputContainer}>
                            <h4 className={styles.textInputLabel}>Unit Price</h4>
                            <input
                                className={styles.textInput}
                                type="text"
                                value={formData.unitPrice}
                                onInput={onChangeUnitPrice}
                                disabled={isUnitPriceDisabled}
                            />
                        </div>
                        <div className={styles.textInputContainer}>
                            <h4 className={styles.textInputLabel}>Quantity</h4>
                            <input className={styles.textInput} type="text" onChange={onChangeQuantity} />
                        </div>
                        <div className={styles.textInputContainer}>
                            <h4 className={styles.textInputLabel}>Unit</h4>
                            <div className={styles.dropdownContainer}>
                                <SearchableDropdown
                                    options={qtyUnits}
                                    showKeys={["name"]}
                                    placeholder="Not Selected"
                                    onSelect={onSelectQtyUnit}
                                />
                            </div>
                        </div>
                        <div className={styles.textInputContainer}>
                            <h4 className={styles.textInputLabel}>Discount Type</h4>
                            <div className={styles.dropdownContainer}>
                                <SearchableDropdown
                                    options={discountTypes}
                                    showKeys={["name"]}
                                    placeholder="Not Selected"
                                    onSelect={onSelectDiscountType}
                                />
                            </div>
                        </div>
                        <div className={styles.textInputContainer}>
                            <h4 className={styles.textInputLabel}>Discount Value</h4>
                            <input className={styles.textInput} type="text" onChange={onChangeDiscountValue} />
                        </div>
                        <div className={styles.textInputContainer}>
                            <h4 className={styles.textInputLabel}>Discount Unit</h4>
                            <div className={styles.dropdownContainer}>
                                <SearchableDropdown
                                    options={discountUnits}
                                    showKeys={["name"]}
                                    placeholder="Not Selected"
                                    onSelect={onSelectDiscountUnit}
                                />
                            </div>
                        </div>
                        <div className={styles.textInputContainer}>
                            <h4 className={styles.textInputLabel}>Total Amount</h4>
                            <input
                                className={styles.textInput}
                                type="text"
                                disabled={isTotalAmountDisabled}
                                value={formData.totalAmount}
                                onInput={onChangeTotalAmount}
                            />
                        </div>
                    </div>
                </section>

                <section className={styles.formSection} >
                    <div className={styles.sectionTitleContainer}>
                        <MdOutlineSell />
                        <h4 className={styles.sectionTitle}>SELLING SECTION</h4>
                    </div>
                    <div className={styles.bottomDiv}>
                        <div className={styles.textInputContainer}>
                            <h4 className={styles.textInputLabel}>Selling Price</h4>
                            <input className={styles.textInput} type="text" onChange={onChangeSellingPrice} />
                        </div>
                        <div className={styles.discountContainer}>
                            <div className={styles.textInputContainer}>
                                <h4 className={styles.textInputLabel}>Selling Discount</h4>
                                <input className={styles.textInput} type="text" onChange={onChangeSellingDiscountValue} />
                            </div>
                            <div className={styles.textInputContainer}>
                                <h4 className={styles.textInputLabel}>Unit</h4>
                                <div className={styles.dropdownContainer}>
                                    <SearchableDropdown
                                        options={discountUnits}
                                        showKeys={["name"]}
                                        placeholder="Not Selected"
                                        onSelect={onChangeSellingDiscountUnit}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.discountContainer}>
                            <div className={styles.textInputContainer}>
                                <h4 className={styles.textInputLabel}>Loyalty Discount</h4>
                                <input className={styles.textInput} type="text" onChange={onChangeLoyaltyDiscountValue} />
                            </div>
                            <div className={styles.textInputContainer}>
                                <h4 className={styles.textInputLabel}>Unit</h4>
                                <div className={styles.dropdownContainer}>
                                    <SearchableDropdown
                                        options={discountUnits}
                                        showKeys={["name"]}
                                        placeholder="Not Selected"
                                        onSelect={onChangeLoyaltyDiscountUnit}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.discountContainer}>
                            <div className={styles.textInputContainer}>
                                <h4 className={styles.textInputLabel}>Wholesale Discount</h4>
                                <input className={styles.textInput} type="text" onChange={onChangeWholesaleDiscountValue} />
                            </div>
                            <div className={styles.textInputContainer}>
                                <h4 className={styles.textInputLabel}>Unit</h4>
                                <div className={styles.dropdownContainer}>
                                    <SearchableDropdown
                                        options={discountUnits}
                                        showKeys={["name"]}
                                        placeholder="Not Selected"
                                        onSelect={onChangeWholesaleDiscountUnit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={styles.formSection} >
                    <div className={styles.buttonPanel}>
                        <div className={styles.submitButton} onClick={onSubmit} >
                            Submit
                        </div>
                        <div className={styles.cancelButton} onClick={() => setOpenAddInvoicePopup(false)}>
                            Cancel
                        </div>
                    </div>
                </section>
            </div>
        );
    }

    return (
        <PopupModel
            title={Title()}
            content={Content()}
            open={isOpenAddInvoicePopup}
            setOpen={setOpenAddInvoicePopup}
        />
    );
}

const mapStateToProps = (state) => ({
    supplierInvoiceState: state.supplierInvoiceState,
});

export default connect(mapStateToProps, {
    searchProducts,
    showAlert,
})(AddItemPopup);