import {
  SET_ALERT_OPEN,
  SET_MOB_SIDE_NAV_BAR_VISIBILITY,
} from "../actions/types";

const initialState = {
  isAlertOpen: false,
  alertType: "success",
  alertMessage: null,
  showMobSideNavBar: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ALERT_OPEN:
      return {
        ...state,
        ...payload,
      };

    case SET_MOB_SIDE_NAV_BAR_VISIBILITY:
      return {
        ...state,
        showMobSideNavBar: payload,
      };
    default:
      return state;
  }
}
