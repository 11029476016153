import Axios from "../../../common/util/Axios";

const setHeaders = token => {
  if (token) {
    Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete Axios.defaults.headers.common["Authorization"];
  }

  Axios.defaults.headers.common["Content-Type"] = "application/json";
};

export default setHeaders;
