import React from "react";
import styles from "./index.module.css";
import OutsideClickHandler from "react-outside-click-handler";
import { RiCloseLine } from "react-icons/ri";
import { FiFilter } from "react-icons/fi";

const FilterDropDown = ({
  selectedFilterItem,
  setSelectedFilterItem,
  showFilterDropdown,
  setShowFilterDropdown,
  filterDropdownItems,
}) => {
  return (
    <div className={styles.mainDiv}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setShowFilterDropdown(false);
        }}
      >
        <div className={styles.filterDiv}>
          <div
            onClick={() => {
              setShowFilterDropdown(!showFilterDropdown);
            }}
            className={styles[`filter`]}
          >
            {selectedFilterItem ? (
              <>
                {selectedFilterItem}
                <RiCloseLine
                  onClick={() => {
                    setSelectedFilterItem(null);
                  }}
                  className={styles[`filterCloseIcon`]}
                />
              </>
            ) : (
              <>
                <FiFilter className={styles[`filter-icon`]} />
                Filters
              </>
            )}
          </div>
          <div
            className={
              showFilterDropdown
                ? styles.filterBottomDiv
                : styles.noFilterBottomDiv
            }
          >
            {filterDropdownItems.map((item) => (
              <>
                {item.name === selectedFilterItem ? (
                  <></>
                ) : (
                  <div
                    className={styles.filterBottomItem}
                    onClick={() => {
                      setSelectedFilterItem(item.name);
                      setShowFilterDropdown(false);
                    }}
                  >
                    {item.name}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default FilterDropDown;
