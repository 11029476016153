import "./index.css";
import PropTypes from "prop-types";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";

const PieChartCard = ({ title, data }) => {
  const colorArray = ["#10B2D0", "#095664", "#98D0DA"];
  const piechartData = [];
  for (let i = 0; i < data.length; i++) {
    piechartData.push({
      ...data[i],
      color: colorArray[i],
    });
  }

  return (
    <div className="card-large-body">
      <h2>{title}</h2>
      <PieChart
        margin={10}
        series={[
          {
            highlightScope: { faded: "series", highlighted: "item" },
            faded: { innerRadius: 60, additionalRadius: -2 },
            arcLabel: item => `${item.value}`,
            arcLabelMinAngle: 30,
            data: piechartData,
            innerRadius: 70,
            outerRadius: 100,
            paddingAngle: 5,
            cornerRadius: 5,
            startAngle: -180,
            endAngle: 180,
            cx: 150,
            cy: 130,
          },
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: "white",
            fontWeight: "bold",
          },
        }}
      />
    </div>
  );
};

PieChartCard.propTypes = {
  amount: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default PieChartCard;
