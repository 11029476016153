import "./index.css";
import logoImage from "../../assets/logo.png";

const Logo = () => {
  return (
    <div className="logo-and-name">
      <img src={logoImage} alt="Logo" />
      <h2>EPINICS</h2>
    </div>
  );
};

export default Logo;
