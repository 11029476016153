import React, { useEffect } from "react";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faCube, faCog } from "@fortawesome/free-solid-svg-icons";
import { TbTruckDelivery } from "react-icons/tb";
import { AiOutlineLineChart } from "react-icons/ai";
import { LuUsers2 } from "react-icons/lu";
import { AiOutlineProduct } from "react-icons/ai";
import { PiUsersThreeBold } from "react-icons/pi";
import { LuHistory } from "react-icons/lu";
import { TbReportAnalytics } from "react-icons/tb";
import { TbSettings } from "react-icons/tb";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdOutlineInventory2,
  MdCalendarViewWeek,
} from "react-icons/md";
import PropTypes from "prop-types";

import { LuTags } from "react-icons/lu";
import styles from "./index.module.css";
import { connect } from "react-redux";
import { setMobSideNavBarVisibility } from "../../../../common/actions/index";

const SideNavBar = ({
  setMobSideNavBarVisibility,
  common: { showMobSideNavBar },
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [openedSubMenuId, setOpenedSubMenuId] = useState(null);

  const menuItems = [
    {
      id: 1,
      name: "Dashboard",
      icon: <AiOutlineLineChart />,
      route: "/",
    },
    {
      id: 2,
      name: "Inventory",
      icon: <MdOutlineInventory2 />,
      route: "/inventory",
      children: [
        // {
        //   id: 1,
        //   name: "Stocks",
        //   icon: <MdCalendarViewWeek />,
        //   route: "/inventory/stocks",
        // },
        // {
        //   id: 2,
        //   name: "Suppliers",
        //   icon: <TbTruckDelivery />,
        //   route: "/inventory/suppliers",
        // },
        {
          id: 3,
          name: "Invoices",
          icon: <TbTruckDelivery />,
          route: "/inventory/invoices",
        },
      ],
    },
    // {
    //   id: 3,
    //   name: "Promotions",
    //   icon: <LuTags />,
    //   route: "/promotions",
    // },
    // {
    //   id: 4,
    //   name: "Customers",
    //   icon: <PiUsersThreeBold />,
    //   route: "/customers",
    //   children: [
    //     {
    //       id: 1,
    //       name: "Stocks",
    //       icon: <MdCalendarViewWeek />,
    //       route: "/stocks",
    //     },
    //     {
    //       id: 2,
    //       name: "Suppliers",
    //       icon: <TbTruckDelivery />,
    //       route: "/suppliers",
    //     },
    //   ],
    // },
    // {
    //   id: 5,
    //   name: "Product Categories",
    //   icon: <AiOutlineProduct />,
    //   route: "/product-categories",
    // },
    // {
    //   id: 6,
    //   name: "Users",
    //   icon: <LuUsers2 />,
    //   route: "/users",
    //   children: [
    //     {
    //       id: 1,
    //       name: "Stocks",
    //       icon: <MdCalendarViewWeek />,
    //       route: "/stocks",
    //     },
    //     {
    //       id: 2,
    //       name: "Suppliers",
    //       icon: <TbTruckDelivery />,
    //       route: "/suppliers",
    //     },
    //   ],
    // },
    // {
    //   id: 7,
    //   name: "Bill History",
    //   icon: <LuHistory />,
    //   route: "/bill-history",
    // },
    // {
    //   id: 8,
    //   name: "Reports",
    //   icon: <TbReportAnalytics />,
    //   route: "/reports",
    // },
    // {
    //   id: 7,
    //   name: "Settings",
    //   icon: <TbSettings />,
    //   route: "/settings",
    // },
  ];

  const clickOnMenuItem = (route) => {
    setMobSideNavBarVisibility(showMobSideNavBar);
    navigate(route);
  };

  const clickOnMenuItemWithChildren = (menuItem) => {
    if (openedSubMenuId === menuItem.id) {
      setOpenedSubMenuId(false);
    } else {
      setOpenedSubMenuId(menuItem.id);
    }
  }

  return (
    <div className="side-nav-bar">
      {menuItems.map((menuItem, i) => {
        return (
          <div className="menu-item-container">
            <div
              className={
                location.pathname === menuItem.route
                  ? "select-menu-item"
                  : "menu-item"
              }
              onClick={() =>
                menuItem.children ? clickOnMenuItemWithChildren(menuItem) : clickOnMenuItem(menuItem.route)
              }
            >
              <div className={styles.innerMenuItemDiv}>
                <span className={styles.menuIcon}>{menuItem.icon}</span>{" "}
                <h3>{menuItem.name}</h3>
              </div>
              {menuItem.children ? (
                <>
                  {openedSubMenuId === menuItem.id ? (
                    <div
                      onClick={() => {
                        setOpenedSubMenuId(false);
                      }}
                    >
                      <MdKeyboardArrowUp className={styles.arrow} />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setOpenedSubMenuId(menuItem.id);
                      }}
                    >
                      <MdKeyboardArrowDown className={styles.arrow} />
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>

            {menuItem.children && openedSubMenuId === menuItem.id ? (
              menuItem.children.map((subItem) => (
                <div className={styles.subMenuDiv}>
                  <div
                    className={
                      location.pathname === subItem.route
                        ? styles.selectSubMenuItem
                        : styles.subMenuItem
                    }
                    onClick={() => clickOnMenuItem(subItem.route)}
                  >
                    <div className={styles.innerMenuItemDiv}>
                      <span className={styles.menuIcon}>{subItem.icon}</span>{" "}
                      <h3>{subItem.name}</h3>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        );
      })}
      <div className="mob-spacer"></div>
    </div>
  );
};

SideNavBar.propTypes = {
  setMobSideNavBarVisibility: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  common: state.commonState,
});

export default connect(mapStateToProps, {
  setMobSideNavBarVisibility,
})(SideNavBar);
