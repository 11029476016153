import {
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_FAILED,
  SET_IS_LOADING,
  SET_BRANCH_ID,
  LOGOUT,
} from "./types";
import Axios from "../../../common/util/Axios";

// Submit a form request
export const login = (username, password) => async dispatch => {
  try {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const reqBody = {
      username,
      password,
    };
    const res = await Axios.post(`/api/dashboard/auth/login`, reqBody);

    if (res.data.success) {
      dispatch({
        type: AUTHENTICATE_SUCCESS,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: AUTHENTICATE_FAILED,
        payload: res.data.error,
      });
    }
  } catch (err) {
    dispatch({
      type: AUTHENTICATE_FAILED,
      payload: err.response.data.error,
    });
  }
};

// Verify token
export const verifyToken = token => async dispatch => {
  try {
    const reqBody = { token };
    const res = await Axios.post(`/api/dashboard/auth/verify-token`, reqBody);

    if (res.data.success) {
      dispatch({
        type: AUTHENTICATE_SUCCESS,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: AUTHENTICATE_FAILED,
        payload: res.data.error,
      });
    }
  } catch (err) {
    dispatch({
      type: AUTHENTICATE_FAILED,
      payload: err.response.data.error,
    });
  }
};

export const setBranchId = id => dispatch => {
  dispatch({
    type: SET_BRANCH_ID,
    payload: id,
  });
};

// Logout /clear everthing
export const logout = () => async dispatch => {
  dispatch({
    type: LOGOUT,
  });
};
