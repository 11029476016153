import "./index.css";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import PropTypes from "prop-types";

const CardSmall = ({ amount, color, title, value }) => {
  return (
    <div className="card-small-body">
      <h2>{title}</h2>
      <div className="card-small-bottom-container">
        <h1>{amount}</h1>
        <div className={`small-div-indicator small-${color}`}>
          <h3>{value}</h3>
          {color === "green" ? (
            <NorthIcon fontSize="inherit" />
          ) : (
            <SouthIcon fontSize="inherit" />
          )}
        </div>
      </div>
    </div>
  );
};

CardSmall.propTypes = {
  amount: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default CardSmall;
