import React, { useState } from "react";
import "./index.css";
import Login from "./Login";
import LinearProgress from "@mui/material/LinearProgress";
import { height } from "@mui/system";
import Loading from "../../../common/components/Loading";
import { Backdrop, CircularProgress } from "@mui/material";

const Landing = () => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="landing-container">
      <div className="progress-bar">
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={isLoading}
          onClick={handleClose}
        >
          <Loading />
        </Backdrop>
      </div>
      <Login setIsLoading={setIsLoading} />
    </div>
  );
};

export default Landing;
