import {
  GET_SALES_DATA_BY_DATE,
  GET_SALES_DATA_BY_DATE_RANGE,
  GET_PAYMENT_METHOD_WISE_BILL_COUNT,
  GET_SALES_DATA_AGAINST_DATE,
  GET_PAYMENT_METHOD_WISE_BILL_COUNT_RANGE,
  SET_RANGE_STATE_CHANGE_LOADING,
  SET_TODAY_STATE_CHANGE_LOADING,
} from "../actions/types";

const initialState = {
  loadingTodayData: true,
  loadingRangeData: true,
  pieChartTodayLoading: true,
  loadingGraphData: true,
  pieChartRangeLoading: true,
  totalSalesToday: "",
  totalCostToday: "",
  totalProfitToday: "",
  numberOfBillsToday: 0,
  totalSalesRange: "",
  totalCostRange: "",
  totalProfitRange: "",
  numberOfBillsRange: 0,
  salesWiseBillCountToday: [],
  salesDataAgainstDateForGraph: [],
  salesWiseBillCountRange: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SALES_DATA_BY_DATE:
      return {
        ...state,
        totalSalesToday: payload.totalSum,
        totalCostToday: payload.totalCost,
        totalProfitToday: payload.totalProfit,
        numberOfBillsToday: payload.billCount,
        loadingTodayData: false,
      };
    case GET_SALES_DATA_BY_DATE_RANGE:
      return {
        ...state,
        totalSalesRange: payload.totalSum,
        totalCostRange: payload.totalCost,
        totalProfitRange: payload.totalProfit,
        numberOfBillsRange: payload.billCount,
        loadingRangeData: false,
      };
    case GET_PAYMENT_METHOD_WISE_BILL_COUNT:
      return {
        ...state,
        salesWiseBillCountToday: payload,
        pieChartTodayLoading: false,
      };
    case GET_SALES_DATA_AGAINST_DATE:
      return {
        ...state,
        salesDataAgainstDateForGraph: payload,
        loadingGraphData: false,
      };
    case GET_PAYMENT_METHOD_WISE_BILL_COUNT_RANGE:
      return {
        ...state,
        salesWiseBillCountRange: payload,
        pieChartRangeLoading: false,
      };
    case SET_RANGE_STATE_CHANGE_LOADING:
      return {
        ...state,
        loadingRangeData: true,
        pieChartRangeLoading: true,
        loadingGraphData: true,
      };
    case SET_TODAY_STATE_CHANGE_LOADING:
      return {
        ...state,
        loadingTodayData: true,
        pieChartTodayLoading: true,
      };
    default:
      return state;
  }
}
