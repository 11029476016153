import "rsuite/dist/rsuite-no-reset.min.css";
import * as React from "react";
import "./index.css";
import { DateRangePicker } from "rsuite";

const BasicDateRangePicker = ({ setDateRange, dateRange }) => {
  const handleDateChange = dates => {
    setDateRange(dates);
  };

  return (
    <div className="date-range-container" id="dateRangePickerContainer">
      <DateRangePicker
        onChange={e => handleDateChange(e)}
        showOneCalendar
        placement="bottomEnd"
        value={dateRange}
      />
    </div>
  );
};

export default BasicDateRangePicker;
